import React, { useEffect } from 'react';
//import './App.css';

function App() {

  useEffect(() => {
    /* global $ */
    $('#myTab a').click(function (e) {
      e.preventDefault();
      $(this).tab('show');
    })
  }, []);

  return (


    <div className="container">
      <div className="col-lg-8 col-lg-offset-2" style={{ marginBottom: 100 }}>

        <div className="row w">
          <div className="col-md-4">
            <img className="img-responsive" src="/avatar.jpg" alt="" />
            <ul className="nav nav-tabs nav-stacked" id="myTab">
              <li className="active"><a href="#about">About</a></li>
              <li><a href="#profile">Profile</a></li>

              <li><a href="#contact">Contact</a></li>
            </ul>
          </div>

          <div className="col-md-8">
            <div className="tab-content">


              <div className="tab-pane active" id="about">
                <h3>Hi! I'm Ryan Vanderpol.</h3>
                <p>I build engineering teams that create web and mobile apps for startups, small businesses, and even a few Fortune 100 companies.
					   I'd love to <a href="mailto:me@ryanvanderpol.com">talk to you</a> about how I can help your team.
					</p>
                <p>Over the last 15 years I've been incredibly fortunate and have built products and worked with engineering teams for all kinds of companies.
					   I currently run <a href="http://betaacid.co" target="_blank" rel="noopener noreferrer">Beta Acid</a>, a software agency in NYC that helps companies of all sizes build software products.
					   Before that, I ran several engineering teams in NYC and China for <a href="https://www.theknot.com" target="_blank" rel="noopener noreferrer">The Knot</a>.
					   Prior to that, I opened a brewery in Seattle called <a href="http://flyingbike.coop" target="_blank" rel="noopener noreferrer">Flying Bike</a>,
					   cofounded a sports-tech startup, <a href="http://gomaxone.com" target="_blank" rel="noopener noreferrer">MaxOne</a>,
					   and a digital menu board system called <a href="http://pourwall.com" target="_blank" rel="noopener noreferrer">POURWALL</a>.
					</p>
                <p>I spent many years in the Seattle startup scene and now live in New York City. I'm a bit of a beer snob. An avid snowboarder. Runner. Adventurer. Aspiring frequent flyer. Coffee connoisseur. Enjoyer of life.</p>
                <p>Sometimes I pretend I'm <a href="https://instagram.com/ryan.vanderpol">a photographer</a>. My dog, <a href="https://instagram.com/barleyhops">Barley</a>, has his own Instagram, too.</p>
                <p className="pull-right red"><a href="mailto:me@ryanvanderpol.com"><i className="fa fa-heart"></i></a></p>
              </div>


              <div className="tab-pane" id="profile">
                <h4><i className="fa fa-briefcase"></i> Experience</h4>
                <p className="sm">
                  <a href="https://betaacid.co" target="_blank" rel="noopener noreferrer"><span className="grey">Beta Acid</span></a> | Principal | Now.<br />
                  <a href="https://www.theknot.com" target="_blank" rel="noopener noreferrer"><span className="grey">The Knot</span></a> | Engineering Manager | 2018 - 2019.<br />
                  <a href="https://gomaxone.com" target="_blank" rel="noopener noreferrer"><span className="grey">MaxOne</span></a> | CTO &amp; Co-Founder | 2014 - 2018.<br />
                  <a href="https://pourwall.com" target="_blank" rel="noopener noreferrer"><span className="grey">POURWALL</span></a> | CTO &amp; Co-Founder | 2014 - Now.<br />

                  <a href="http://getcampfire.co" target="_blank" rel="noopener noreferrer"><span className="grey">Campfire</span></a> | Director of Engineering | 2015 - 2016.<br />
                  <a href="http://flyingbike.coop" target="_blank" rel="noopener noreferrer"><span className="grey">Flying Bike Coop Brewery</span></a> | President/Director | 2012 - 2016.<br />
                  <a href="https://placefull.com" target="_blank" rel="noopener noreferrer"><span className="grey">PlaceFull</span></a> | CTO &amp; Product Architect | 2013 - 2014.<br />
                  <span className="grey">Promethean</span> | Sr. Product Lead | 2008 - 2012.<br />
                </p>

                <h4><i className="fa fa-flask"></i> Projects</h4>
                <p className="sm">
                  <a href="http://paviasystems.com" target="_blank" rel="noopener noreferrer"><span className="grey">Pavia Systems</span></a> | Headlight app platform<br />
                  <a href="http://nectjobs.com" target="_blank" rel="noopener noreferrer"><span className="grey">Nect</span></a> | Recruitment platform<br />
                  <a href="http://www.pteglv.com/" target="_blank" rel="noopener noreferrer"><span className="grey">PTEG</span></a> | Mobile loyalty<br />
                  <a href="https://github.com/ryanvanderpol/albuquerque" target="_blank" rel="noopener noreferrer"><span className="grey">Albuquerque</span></a> | A/B Testing for Node.js<br />
                  <span className="grey">X2 Biosystems</span> | Concussion Management System<br />
                  <span className="grey">McGraw-Hill Education</span> | Power of U<br />
                  <span className="grey">Dash Navigation / RIM</span> | Data Integration<br />
                  <span className="grey">R.E. Lee, International</span> | Commission Management<br />
                  <span className="grey">The Onion</span> | Data Integration<br />
                </p>

                <h4><i className="fa fa-newspaper-o"></i> Press</h4>
                <p className="sm">
                  <a href="http://www.king5.com/story/news/local/seattle/2016/01/03/love-of-craft-brews-helps-shape-washington-hop-harvest/78243974/"><span className="grey">KING 5</span></a> | Craft Beer Shaping Washington Hop Harvest<br />
                  <a href="http://komonews.com/news/local/washington-states-first-co-op-brewery-opens-in-seattle-11-21-2015"><span className="grey">KOMO 4</span></a> | Washington's First Co-op Brewery Opens<br />
                  <a href="http://www.washingtonbeerblog.com/flying-bike-cooperative-brewery-seeks-raise-300k-member-investment-campaign/"><span className="grey">Seattle PI</span></a> | Brewery Raises $300k<br />
                  <a href="http://www.geekwire.com/2012/jumyo-storytelling-app-social-media/"><span className="grey">Geekwire</span></a> | Social Storytelling<br />
                  <a href="http://www.npr.org/templates/story/story.php?storyId=122298649"><span className="grey">NPR</span></a> | Technology Sector Growth<br />
                </p>

                <h4><i className="fa fa-trophy"></i> Patents</h4>
                <p className="sm">
                  <span className="grey">Roster Building Interfaces</span> | United States 20100257483.<br />
                </p>
              </div>


              <div className="tab-pane" id="contact">
                <h4>Resume</h4>
                <hr />
                <div className="row">
                  <div className="col-xs-6">
                    <p className="sm">
                      <i className="fa fa-cloud"></i>&nbsp;&nbsp; <a href="/RyanVanderpol-Resume.pdf">My Resume</a> <br />
                    </p>
                  </div>
                </div>

                <h4>Contact Information</h4>
                <hr />
                <div className="row">
                  <div className="col-xs-6">
                    <p className="sm">
                      <i className="fa fa-globe"></i>&nbsp;&nbsp; <a href="http://ryanvanderpol.com/">ryanvanderpol.com</a> <br />
                      <i className="fa fa-envelope"></i>&nbsp;&nbsp; <a href="mailto:me@ryanvanderpol.com">me@ryanvanderpol.com</a>
                    </p>
                  </div>

                </div>

                <h4>Social Links</h4>
                <hr />
                <div className="row">
                  <div className="col-xs-6">
                    <p className="sm">
                      <i className="fa fa-linkedin"></i>&nbsp;&nbsp; <a href="http://www.linkedin.com/in/ryanvanderpol/">LinkedIn</a>  <br />
                      <i className="fa fa-twitter"></i>&nbsp;&nbsp; <a href="http://twitter.com/ryan_vanderpol">Twitter</a> <br />
                    </p>
                  </div>

                  <div className="col-xs-6">
                    <p className="sm">
                      <i className="fa fa-instagram"></i>&nbsp;&nbsp; <a href="http://instagram.com/ryan.vanderpol">Instagram</a> <br />
                      <i className="fa fa-dribbble"></i>&nbsp;&nbsp; <a href="https://dribbble.com/ryanvanderpol">Dribbble</a> <br />
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>




  );
}

export default App;
